import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AuthGoogleService } from '../services/auth-google.service';
import { environment } from '../../environments/environment';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  // Inject AuthGoogleService to access the token
  private authService = inject(AuthGoogleService);

  // Intercept all outgoing HTTP requests
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request is targeting the backend URL (only apply token if true)
    if (req.url.startsWith(environment.backendUrl)) {
      // Convert the token retrieval (which returns a Promise) into an Observable
      return from(this.authService.getToken()).pipe(
        // Use switchMap to handle the async token retrieval and forward the modified request
        switchMap((token) => {
          // If the token is available, clone the request and set the Authorization header
          if (token) {
            const authReq = req.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            });
            // Pass the modified request with the token to the next handler
            return next.handle(authReq);
          } else {
            // If no token is available, forward the original request without modifications
            return next.handle(req);
          }
        })
      );
    } else {
      // If the request URL does not match the backend URL, forward the request without changes
      return next.handle(req);
    }
  }
}
